import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NgIf, NgFor, isPlatformBrowser } from '@angular/common';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TAGS } from 'src/app/tags';
import { Router } from '@angular/router';
import { BlogService } from 'src/app/blog.service';

@Component({
  selector: 'app-tags-notification-popup',
  standalone: true,
  imports: [NgIf, FontAwesomeModule, NgFor],
  templateUrl: './tags-notification-popup.component.html',
  styleUrl: './tags-notification-popup.component.scss'
})
export class TagsNotificationPopupComponent implements OnInit{

    browserMode:boolean;
    faTimes = faTimes;
    isVisible = false;
    tagDetails: Array<Record<string, any>>;
    hiddenRoutes: boolean = true;

    constructor(
        private router: Router,
        private blogService: BlogService,
        @Inject(PLATFORM_ID) platformId: string,
    ) {
        this.browserMode = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        this.blogService.tagsNotificationPopupData$.subscribe(data => {
            this.isVisible = data;
        });

        this.tagDetails = TAGS.map(tag => ({
            name: tag.name,
            image: tag.image
        }));
    }

    closePopup() {
        this.isVisible = false;
    }

    redirect() {
        this.closePopup();
        if(!this.router.url.includes('/best-bets') && !this.router.url.includes('/best-props')){
            this.router.navigate(['/best-props']);
        }
        this.blogService.setTagsHelpInfoModalData(true);
    }
}
