import { environment } from './environments/environment';


import { HoneycombWebSDK } from '@honeycombio/opentelemetry-web';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';

const configDefaults = {
	ignoreNetworkEvents: environment.production, // <-- ONLY FALSE IN DEV - LOTS OF EVENTS
	propagateTraceHeaderCorsUrls: [/.+statsinsider\.com\.au.+/gm, /(?!.+auth\.dimers\.com)(?=.+dimers\..*com)^.+$/gm]
}

const sdk = new HoneycombWebSDK({
  debug: !environment.production, // Set to false for production environment.
  apiKey: environment.honeycombFrontendKey, // Replace with your Honeycomb Ingest API Key.
  serviceName: 'dimers-frontend',
  instrumentations: [getWebAutoInstrumentations({
	  '@opentelemetry/instrumentation-xml-http-request': configDefaults,
	  '@opentelemetry/instrumentation-fetch': configDefaults,
	  '@opentelemetry/instrumentation-document-load': configDefaults,
	// '@opentelemetry/instrumentation-user-interaction': {
	// 	eventNames: ['submit', 'click', 'keypress'],
	// },
  })],
  sampleRate: environment.production ? 20 : 1, // only send every 20th trace to Honeycomb on production, to keep under rate limits (send everything on non-production)
});
sdk.start();

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppBrowserModule } from './app/app.browser.module';

if (environment.production) {
	enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
     platformBrowserDynamic().bootstrapModule(AppBrowserModule)
	.catch(err => console.error(err));
   });
