<div class="modal-overlay fixed w-full h-full top-0 left-0 z-[999] no-doc-scroll" *ngIf="isVisible"> 
    <div class="backdrop-blur-sm bg-black/50 w-full h-full"  (click)="closePopup()">
	</div>
    <div
        class="modal-dialog flex max-h-[80%] w-full max-w-[600px] sm:w-[600px] bottom-0 sm:bottom-auto sm:top-1/2 left-1/2 -translate-x-1/2 sm:-translate-y-1/2 absolute">
        <div class="bg-white rounded-lg overflow-auto p-6 w-full">
            <div class="flex items-center justify-center">
                <img src="https://imgix.cosmicjs.com/78a729c0-b117-11ef-9ee7-2bfd87cd6dc9-All-signals---New.png?auto=compress,enhance,format&w=100" alt="image for all signals" width="70" height="70" loading="lazy">
            </div>
            <div class="text-lg text-gray-900 font-semibold text-center">
                Get Dimers Signals now with Dimers Pro
            </div>
            <div class="text-base text-gray-900 font-medium text-center">
                Find the Best of the Best Bets and Props easier now!
            </div>
            <div class="flex justify-center py-4 sm:py-6">   
                <ng-container *ngFor="let tag of tagDetails; let i = index;">
                    <div class="bg-white rounded-full flex justify-center items-center shadow-md border border-gray-200 h-12 sm:h-16 md:h-20 w-12 sm:w-16 md:w-20 first:ml-0 -ml-4 sm:-ml-4">
                        <img [src]="tag.image" alt="Image for {{ tag.name }}" loading="lazy" class="h-12 w-12 sm:h-[58px] sm:w-[58px]" width="58" height="58">
                    </div>
                </ng-container>
            </div>
            <div class="flex items-center justify-center">
                <button (click)="redirect()" class="text-base leading-6 text-white font-medium bg-highlight-100 whitespace-nowrap hover:bg-highlight-50 shadow rounded-md border-0 ring-1 ring-inset ring-highlight-100 px-4 py-2 transition-all delay-75">
                    Show me
                </button>
            </div>
        </div>
        <div class="flex md:hidden close-button leading-none top-2 right-2 cursor-pointer absolute h-[28px] min-w-[28px] bg-black/60 rounded-full items-center justify-center" (click)="closePopup()">
            <fa-icon class="text-white text-sm" [icon]="faTimes" aria-label="Close"></fa-icon>
        </div>
        <div class="hidden cursor-pointer md:flex items-center justify-center h-[32px] min-w-[32px] bg-black/60 rounded-full ml-2 hover:bg-black" (click)="closePopup()">
            <fa-icon class="text-white" [icon]="faTimes" aria-label="Close"></fa-icon>
        </div>
    </div>
</div>